<template>
  <v-carousel 
    :show-arrows="false"
    cycle
    hide-delimiter-background
  >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    data () {
      return {
        items: [
          {
            src: require('../assets/banner1.png'),
          },
        ],
      }
    },
  }
</script>