<template>
  <div>
    <Header />

    <v-carousel cycle height="80" interval="4000" hide-delimiters :show-arrows="false" class="mt-10">
      <v-carousel-item>
        <v-row class="fill-height" align="center" justify="center">
          <div>
            <span class="tamanhofonte amarelo">Saia do</span>
            <span class="tamanhofonte branco">&nbsp;ANALÓGICO!</span>
          </div>
        </v-row>
      </v-carousel-item>
      <v-carousel-item>
        <v-row class="fill-height" align="center" justify="center">
          <div>
            <span class="tamanhofonte amarelo">Possibilidades</span>
            <span class="tamanhofonte branco">&nbsp;INFINITAS</span>
          </div>
        </v-row>
      </v-carousel-item>
      <v-carousel-item>
        <v-row class="fill-height" align="center" justify="center">
          <div>
            <span class="tamanhofonte amarelo">Modo</span>
            <span class="tamanhofonte branco">&nbsp;REVOLUCIONÁRIO</span>
            <span class="tamanhofonte amarelo">&nbsp;e</span>
            <span class="tamanhofonte branco">&nbsp;DEFINITIVO</span>
          </div>
        </v-row>
      </v-carousel-item>
      <v-carousel-item>
        <v-row class="fill-height" align="center" justify="center">
          <div>
            <span class="tamanhofonte amarelo">Seus</span>
            <span class="tamanhofonte branco">&nbsp;CLIENTES</span>
            <span class="tamanhofonte amarelo">&nbsp;mais</span>
          </div>
        </v-row>
      </v-carousel-item>
    </v-carousel>

    <v-carousel cycle height="80" interval="4000" hide-delimiters :show-arrows="false">
      <v-carousel-item>
        <v-row class="fill-height" align="center" justify="center">
          <div>
            <span class="tamanhofonte amarelo">Venha para o</span>
            <span class="tamanhofonte branco">&nbsp;DIGITAL!</span>
          </div>
        </v-row>
      </v-carousel-item>
      <v-carousel-item>
        <v-row class="fill-height" align="center" justify="center">
          <div>
            <span class="tamanhofonte amarelo">Em um</span>
            <span class="tamanhofonte branco">&nbsp;ÚNICO CARTÃO</span>
          </div>
        </v-row>
      </v-carousel-item>
      <v-carousel-item>
        <v-row class="fill-height" align="center" justify="center">
          <div>
            <span class="tamanhofonte amarelo">Para compartilhar suas</span>
            <span class="tamanhofonte branco">&nbsp;REDES SOCIAIS</span>
          </div>
        </v-row>
      </v-carousel-item>
      <v-carousel-item>
        <v-row class="fill-height" align="center" justify="center">
          <div>
            <span class="tamanhofonte amarelo">próximos da sua</span>
            <span class="tamanhofonte branco">&nbsp;EMPRESA</span>
          </div>
        </v-row>
      </v-carousel-item>
    </v-carousel>

    <v-row justify="center">
      <v-col cols="6">
        <v-img :src="require('@/assets/cards2.png')" class="cards" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="textoprincipal index me-15 md-15">
        No formato de um cartão de crédito, INFINITY CARD é um dispositivo que
        substitui o cartão de visitas tradicional de papel.
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="textofunciona index me-15 md-15"> COMO FUNCIONA? </v-col>
    </v-row>
    <v-row>
      <v-hover         
        v-slot="{ hover }"
      >
        <v-card
          class="mx-auto"
          color="#263441"
          justify="center"
          dark
          max-width="400"
          :elevation="hover ? 12 : 2"
        >
          <v-img height="200px" :src="require('@/assets/aproxima.jpg')" />
          <v-card-title>
            <span class="text-h4 font-weight-light">Por APROXIMAÇÃO</span>
          </v-card-title>

          <v-card-text class="text-h3 font-weight-bold">
            Basta encostar no celular para compartilhar
          </v-card-text>
        </v-card>
      </v-hover>

      <v-hover         
        v-slot="{ hover }"
      >
        <v-card
          class="mx-auto"
          color="#263441"
          justify="center"
          dark
          max-width="400"
          :elevation="hover ? 12 : 2"
        >
          <v-img height="200px" :src="require('@/assets/qrcode.jpg')" />
          <v-card-title>
            <span class="text-h4 font-weight-light">Por QR CODE</span>
          </v-card-title>

          <v-card-text class="text-h3 font-weight-bold">
            Basta aproximar a câmera para ler o qrcode e compartilhar
          </v-card-text>
        </v-card>
      </v-hover>

      <v-hover         
        v-slot="{ hover }"
      >
        <v-card
          class="mx-auto"
          color="#263441"
          justify="center"
          dark
          max-width="400"
          :elevation="hover ? 12 : 2"
        >
          <v-img height="200px" :src="require('@/assets/compartilha.jpg')" />
          <v-card-title>
            <span class="text-h4 font-weight-light">COMPARTILHA</span>
          </v-card-title>

          <v-card-text class="text-h3 font-weight-bold">
            seus dados e redes sociais
          </v-card-text>
        </v-card>
      </v-hover>

    </v-row>
    <v-row>
      <v-col class="saibamais"> SAIBA MAIS </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" />
      <v-col class="detalhes"
        ><v-icon>mdi-check-decagram</v-icon> Baixo custo</v-col
      >
    </v-row>
    <v-row>
      <v-col cols="3" />
      <v-col class="detalhes margemdetalhes"
        ><v-icon>mdi-check-decagram</v-icon> Não possui mensalidade</v-col
      >
    </v-row>
    <v-row>
      <v-col cols="3" />
      <v-col class="detalhes margemdetalhes"
        ><v-icon>mdi-check-decagram</v-icon> É pago uma única vez</v-col
      >
    </v-row>
    <v-row>
      <v-col cols="3" />
      <v-col class="detalhes margemdetalhes"
        ><v-icon>mdi-check-decagram</v-icon> Sem limite de uso</v-col
      >
    </v-row>
    <v-row>
      <v-col cols="3" />
      <v-col class="detalhes margemdetalhes"
        ><v-icon>mdi-check-decagram</v-icon> Seus dados são totalmente
        personalizáveis</v-col
      >
    </v-row>
    <v-row>
      <v-col cols="3" />
      <v-col class="detalhes margemdetalhes"
        ><v-icon>mdi-check-decagram</v-icon> Através do seu painel on-line edite
        seus dados quantas vezes quiser</v-col
      >
    </v-row>
    <v-row>
      <v-col cols="3" />
      <v-col class="detalhes margemdetalhes"
        ><v-icon>mdi-check-decagram</v-icon> Não precisa de aplicativo para
        funcionar</v-col
      >
    </v-row>
    <v-row>
      <v-col cols="3" />
      <v-col class="detalhes margemdetalhes"
        ><v-icon>mdi-check-decagram</v-icon> Compartilhe suas informações
        ilimitadamente com todos os seus clientes</v-col
      >
    </v-row>
    <v-row justify="center">
      <v-col cols="1" class="mt-15">
        <router-link to="/comprar">
          <v-img :src="require('@/assets/compre.png')" width="150" />
        </router-link>
      </v-col>
    </v-row>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/HeaderHome.vue";
import Carousel from "../components/Carousel.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Header,
    Carousel,
    Footer,
  },
  data() {
    return {
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
};
</script>
<style scoped>
.compartilha {
  background-image: url("../assets/compartilha.jpg");
}
.detalhes {
  color: gray;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
}
.margemdetalhes {
  margin-top: -30px;
}
.saibamais {
  background-color: black;
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin-top: 100px;
  margin-bottom: 50px;
}
.index {
  z-index: 1;
}
.textoprincipal {
  margin-top: 100px;
  font-size: 50px;
  font-weight: bold;
  color: red;
}
.textofunciona {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 50px;
  font-weight: bold;
  color: black;
}
.amarelo {
  color: yellow;
}
.branco {
  color: white;
}
.tamanhofonte {
  font-size: 50px;
}
.cards {
  width: 1000px;
  margin-top: 50px;
}
</style>
